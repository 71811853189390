<template>
  <v-row>
    <v-col cols="12" class="d-flex flex-column align-center">
      <v-icon x-large color="warning"> mdi-wrench </v-icon>
      <p>Currently in development.</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "projectsComponent",
};
</script>

<style></style>
